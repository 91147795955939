import { api } from "services/api";

export const catalogApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductsList: build.query({
      query: ({ page = 1, limit = 10, search, field, value } = {}) => {
        return {
          url: "catalog/search",
          params: { page, limit, search },
        };
      },
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}-${queryArgs.search}`;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          currentCache.data = newItems.data;
        } else {
          currentCache.data.push(...newItems.data);
        }
        currentCache.page = newItems.page;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
    }),
    getProductByBarcode: build.query({
      query: (barcode) => ({
        url: `catalog/${barcode}`,
      }),
    }),
  }),
});

export const { useGetProductsListQuery, useLazyGetProductByBarcodeQuery } =
  catalogApi;
