import Input from "ui/Input";
import IconButton from "ui/IconButton";
import InputAdornment from "ui/InputAdornment";

import Title from "components/Title";
import ProductCard from "components/ProductCard";

import { MONO_PRODUCT_FIELD, MULTI_PRODUCT_FIELD } from "constants/ads";
import { PRODUCT_FIELDS } from "constants/product";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const getAdornment = (field) => {
  if ([PRODUCT_FIELDS.DISCOUNT_PERCENTAGE].includes(field)) {
    return "%";
  }
  if ([PRODUCT_FIELDS.OLD_PRICE, PRODUCT_FIELDS.PRICE].includes(field)) {
    return "$";
  }
  return null;
};

export const STEPS = {
  FORM: "FORM",
  PRODUCTS_FORM: "PRODUCTS_FORM",
  PRODUCTS_CATALOG: "PRODUCTS_CATALOG",
  VIDEO_PLAYER: "VIDEO_PLAYER",
  LOADER: "LOADER",
};

export const renderMonoFields = (
  { properties, isProducts },
  { register, errors, setValue, getValues, trigger },
  ProductSelectActions,
  { onScan, onSelect }
) => {
  const selectedProduct = getValues(MONO_PRODUCT_FIELD);

  const handleDelete = () => {
    trigger(MONO_PRODUCT_FIELD);
    setValue(MONO_PRODUCT_FIELD, null);
  };

  return (
    <>
      {!isProducts &&
        (!!selectedProduct ? (
          <div className="relative flex max-h-[105px] ">
            <ProductCard
              name={selectedProduct?.displayName}
              brand={selectedProduct?.brand?.name}
              variant={selectedProduct?.variant}
              img={selectedProduct?.bsImage?.cdnUrl}
              Header={() => (
                <IconButton
                  aria-label="info"
                  className="absolute top-2 right-2"
                  onClick={handleDelete}
                >
                  <CloseIcon />
                </IconButton>
              )}
              Footer={null}
            />
          </div>
        ) : (
          <ProductSelectActions
            helperText={errors[MONO_PRODUCT_FIELD]?.message}
            error={!!errors[MONO_PRODUCT_FIELD]}
            onScan={onScan}
            onSelect={onSelect}
          />
        ))}

      {Object.entries(properties).map(([key, property]) => {
        if (key !== MULTI_PRODUCT_FIELD) {
          return (
            <div key={key} className="flex flex-col">
              <Input.Label
                // todo
                {...register(key, {
                  setValueAs: (v) => (v === "" ? undefined : v),
                })}
                autoComplete="off"
                label={`Enter ${property?.meta.label ?? key}`}
                multiline={!!property?.meta.multiline}
                type={property?.meta?.type ?? "text"}
                helperText={errors[key]?.message}
                error={!!errors[key]}
                inputProps={{
                  inputMode: property?.meta?.inputMode,
                  step: property.meta?.step,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="text-base-1000 text-sm font-semibold">
                        {getAdornment(key)}
                      </span>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export const renderMultiFields = (
  { properties, definitions },
  { register, errors, setValue, getValues, resetField, trigger },
  ProductSelectActions,
  { onScan, onSelect }
) =>
  Object.entries(properties).map(([propertyKey, property]) => {
    if (propertyKey === MULTI_PRODUCT_FIELD) {
      return (
        <div key={propertyKey}>
          {Object.entries(property.properties).map(
            ([productKey, { $ref }], index) => {
              const path = $ref.replace(/^#\/definitions\//, "");
              const { properties } = definitions[path];
              const selectedProduct = getValues(
                `${MULTI_PRODUCT_FIELD}.${productKey}.${MONO_PRODUCT_FIELD}`
              );

              const handleDelete = () => {
                trigger(
                  `${MULTI_PRODUCT_FIELD}.${productKey}.${MONO_PRODUCT_FIELD}`
                );
                setValue(
                  `${MULTI_PRODUCT_FIELD}.${productKey}.${MONO_PRODUCT_FIELD}`,
                  null
                );
              };

              return (
                <div
                  key={productKey}
                  className="flex flex-col gap-4 py-6 border-b last:border-b-0 border-base-100"
                >
                  <Title size="2xl">Product {index + 1}</Title>
                  {!!selectedProduct ? (
                    <div className="relative flex max-h-[105px] ">
                      <ProductCard
                        name={selectedProduct?.displayName}
                        brand={selectedProduct?.brand?.name}
                        variant={selectedProduct?.variant}
                        img={selectedProduct?.bsImage?.cdnUrl}
                        Header={() => (
                          <IconButton
                            aria-label="info"
                            className="absolute top-2 right-2"
                            onClick={handleDelete}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                        Footer={null}
                      />
                    </div>
                  ) : (
                    <ProductSelectActions
                      helperText={
                        errors[MULTI_PRODUCT_FIELD]?.[productKey]?.message
                      }
                      error={!!errors[MULTI_PRODUCT_FIELD]?.[productKey]}
                      onScan={(e) => onScan(e, productKey)}
                      onSelect={(e) => onSelect(e, productKey)}
                    />
                  )}

                  {Object.entries(properties).map(([key, { meta }]) => (
                    <Input.Label
                      key={key}
                      // todo
                      {...register(`${propertyKey}.${productKey}.${key}`, {
                        setValueAs: (v) => (v === "" ? undefined : v),
                      })}
                      autoComplete="off"
                      label={`Enter ${meta.label ?? key}`}
                      placeholder={meta.placeholder}
                      type={meta.type ?? "text"}
                      helperText={
                        errors?.products?.[productKey]?.[key]?.message
                      }
                      error={!!errors?.products?.[productKey]?.[key]}
                      inputProps={{
                        inputMode: meta?.inputMode,
                        step: meta?.step,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span className="text-base-1000 text-sm font-semibold">
                              {getAdornment(key)}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </div>
              );
            }
          )}
        </div>
      );
    }
    return null;
  });

export default renderMonoFields;
