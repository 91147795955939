import { useDispatch } from "react-redux";

import { hideDrawer } from "store/ducks/ui/drawer";

import Title from "components/Title";
import Button from "ui/Button";

const ProductNotFound = () => {
  const dispatch = useDispatch();

  const handleHideDrawer = () => {
    dispatch(hideDrawer());
  };

  return (
    <div className="flex flex-1 flex-col justify-between gap-8">
      <div className="flex flex-1 flex-col gap-2 items-center justify-center px-8">
        <Title size="2xl">Product not found</Title>
        <span className="text-center text-sm text-base-500">
          Unfortunately, the product you scanned was not found in your catalog
        </span>
      </div>
      <Button
        variant="contained"
        className="sticky bottom-0 w-full"
        onClick={handleHideDrawer}
      >
        Send a request
      </Button>
    </div>
  );
};

export default ProductNotFound;
