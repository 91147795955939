import { useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

import { useGetPlaylistsQuery } from "services/playlists";
import { useDeletePlaylistMutation } from "services/playlists";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";

import { replaceParams } from "utils/router";
import { hideDrawer, showDrawer } from "store/ducks/ui/drawer";

import Section from "components/Section";
import Playlist from "components/Playlist";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";
import SearchInput from "components/SearchInput";
import SkeletonWrapper from "components/SkeletonWrapper";
import EmptyData from "components/EmptyData";
import PlaylistCreateActions from "components/PlaylistCreateActions";

import Button from "ui/Button";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as AssignIcon } from "assets/icons/assign.svg";
import { ReactComponent as RenameIcon } from "assets/icons/rename.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import { PLAYLIST_ACTIONS } from "constants/drawer";
import { PRODUCT_VIEWS } from "constants/index";

import pages from "router/links";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const Playlists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch("");
  const debouncedSearch = useDebounce(700);

  const {
    data: { data: playlistsData = [], total } = {},
    isLoading: isPlaylistsLoading,
    isFetching: isFetchingPlaylists,
  } = useGetPlaylistsQuery({
    page,
    limit: PAGINATION.limit,
    search,
  });
  const [deletePlaylist] = useDeletePlaylistMutation();

  const countPlaylists = playlistsData.length;

  const createPlaylistLink = useMemo(
    () =>
      replaceParams(pages.playlistCreate.path, {
        type: countPlaylists ? "new" : "default",
      }),
    [countPlaylists]
  );

  const menuItems = useMemo(
    () => [
      {
        icon: AssignIcon,
        label: "Assign",
        onClick: (e, { id }) => {
          navigate(
            replaceParams(pages.playlistsAssign.path, {
              playlistId: id,
            })
          );
          dispatch(hideDrawer());
        },
      },
      {
        icon: RenameIcon,
        label: "Rename",
        onClick: (e, { id }) => {
          navigate(
            replaceParams(pages.playlistEdit.path, {
              playlistId: id,
            })
          );
          dispatch(hideDrawer());
        },
      },
      {
        icon: DeleteIcon,
        label: "Delete",
        color: "text-error",
        onClick: (e, { id }) => {
          deletePlaylist(id);
          dispatch(hideDrawer());
        },
      },
    ],
    [deletePlaylist, dispatch, navigate]
  );

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  const handleOpenMenu = ({ id, name }) => {
    dispatch(
      showDrawer({
        content: PLAYLIST_ACTIONS,
        headerProps: {
          title: name,
        },
        data: {
          menuItems,
          id,
        },
      })
    );
  };

  const handleOpenProductsCatalog =
    (view = PRODUCT_VIEWS.CATALOG) =>
    (e) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("view", view);
      navigate({
        pathname: replaceParams(pages.playlistCreate.path, {
          type: "default",
        }),
        search: searchParams.toString(),
      });
    };

  const createButton = (
    <Button
      variant="contained"
      startIcon={<PlusIcon className="w-4" />}
      component={NavLink}
      to={createPlaylistLink}
    >
      Create new
    </Button>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        {/*label SEARCH*/}
        <SearchInput
          placeholder="Playlists"
          defaultValue={search}
          onChange={({ target }) => {
            debouncedSearch(() => {
              setPage(1);
              setSearch(target.value);
            });
          }}
        />

        {/*label PLAYLISTS LIST*/}
        <Section
          title="Playlists"
          buttonLabel="Create New"
          buttonProps={{
            component: NavLink,
            to: createPlaylistLink,
          }}
        >
          <InfiniteScroll
            next={handleMoreData}
            dataLength={playlistsData.length}
            hasMore={page * PAGINATION.limit < total}
            scrollThreshold={0.55}
            loader={<span>Loading...</span>}
          >
            <div className="flex flex-col gap-4">
              <ComponentFallbackRenderer
                isLoading={isFetchingPlaylists}
                data={playlistsData}
                alt={
                  !!search ? (
                    EmptyData
                  ) : (
                    <>
                      <Playlist
                        isDefault
                        content={PlaylistCreateActions}
                        contentProps={{
                          className: "min-h-[120px]",
                          onScan: handleOpenProductsCatalog(
                            PRODUCT_VIEWS.SCANNER
                          ),
                          onAdd: handleOpenProductsCatalog(
                            PRODUCT_VIEWS.CATALOG
                          ),
                        }}
                      />
                      {createButton}
                    </>
                  )
                }
              >
                {(playlists) =>
                  (isFetchingPlaylists
                    ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
                    : playlists
                  ).map(
                    ({
                      uuid,
                      id = uuid,
                      isDefault,
                      name,
                      previews = [],
                      videoUrl,
                    }) => (
                      <SkeletonWrapper
                        key={id}
                        condition={isFetchingPlaylists}
                        Component={Playlist.Skeleton}
                      >
                        <Playlist
                          isDefault={isDefault}
                          name={name}
                          videos={previews}
                          footerRightContentProps={{
                            onClick: () => handleOpenMenu({ id, name }),
                          }}
                          playerProps={{
                            withPlayer: true,
                            url: videoUrl,
                          }}
                        />
                      </SkeletonWrapper>
                    )
                  )
                }
              </ComponentFallbackRenderer>
            </div>
          </InfiniteScroll>
        </Section>
      </div>

      {/*label BUTTON*/}
      {!!countPlaylists && createButton}
    </div>
  );
};

export default Playlists;
