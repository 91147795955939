import cx from "classnames";
import { useNavigate } from "react-router-dom";

import useDeleteParams from "hooks/useDeleteParams";

import IconButton from "ui/IconButton";
import Title from "components/Title";

import { renderComponent } from "utils/components";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow l.svg";
// import { ReactComponent as MoreIcon } from "assets/icons/more.svg";

import styles from "./styles.module.scss";

const CommonHeader = ({
  title,
  description,
  className,
  actionsColor = "text-base-1000",
  ActionSideRight,
  deleteParams,
  onBack,
}) => {
  const navigate = useNavigate();
  const deleteParamsFromUrl = useDeleteParams();

  const handleBackHistory = () => {
    deleteParamsFromUrl(deleteParams);
    if (typeof onBack === "function") {
      onBack();
      return;
    }
    navigate(-1);
  };

  return (
    <header
      className={cx(
        "container mx-auto p-4 grid grid-cols-12 gap-4 items-center min-h-[66px] sticky top-0 bg-base-0 z-10",
        styles.root,
        className
      )}
    >
      <div className="left-content  col-span-2 text-left">
        <IconButton
          aria-label="info"
          className={`p-0 ${actionsColor}`}
          onClick={handleBackHistory}
        >
          <ArrowIcon />
        </IconButton>
      </div>
      <div className="center-content col-span-8 text-center">
        <div className="flex flex-col gap-1">
          <Title className="leading-none">{title}</Title>
          {description && (
            <div className="text-sm text-base-500 leading-none">
              {description}
            </div>
          )}
        </div>
      </div>
      {renderComponent(ActionSideRight)}
      {/*<div className="right-content  col-span-2 text-right">*/}
      {/*  <IconButton aria-label="info" className={`p-0 ${actionsColor}`}>*/}
      {/*    <MoreIcon />*/}
      {/*  </IconButton>*/}
      {/*</div>*/}
    </header>
  );
};

export default CommonHeader;
