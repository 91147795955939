import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import IconButton from "ui/IconButton";
import Skeleton from "ui/Skeleton";

import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

const renderContent = ({ getRootProps, file, onDelete }) => {
  if (file) {
    return (
      <div className="flex items-center justify-between bg-base-50 px-5 py-3 rounded-xl min-h-[80px]">
        <div className="flex gap-2 w-full max-w-screen-lg truncate">
          <VideoIcon />
          <span className="truncate">{file.name}</span>
        </div>
        <IconButton
          aria-label="delete"
          className="text-common-error"
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className="flex flex-col justify-center rounded-xl border border-dashed border-gray-200 px-6 py-4 min-h-[80px] cursor-pointer"
    >
      <div className="flex gap-4 justify-center">
        <UploadIcon />
        <span>Upload from device</span>
      </div>
    </div>
  );
};

const Dropzone = ({ onDrop = () => {}, onDelete = () => {} }) => {
  const [file, setFile] = useState(null);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      onDrop(acceptedFiles);
      setFile(acceptedFiles[0]);
    },
    [onDrop]
  );

  const handleDelete = () => {
    setFile(null);
    onDelete();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      <input {...getInputProps()} />
      {renderContent({ getRootProps, file, onDelete: handleDelete })}
    </>
  );
};

Dropzone.Skeleton = () => <Skeleton.Round />;

export default Dropzone;
