import { useNavigate } from "react-router-dom";
import cx from "classnames";

import { PRODUCT_VIEWS } from "constants/index";
import { PRODUCT_FIELDS } from "constants/product";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

import ProductSelectActions from "pages/AdsCreate/components/ProductSelectActions";

import {
  renderMonoFields,
  renderMultiFields,
  STEPS,
} from "pages/AdsCreate/helpers";

import { getPrice } from "utils/templates";
import { hasProperties } from "utils/index";

import Title from "components/Title";

const renderFields = (
  { register, errors, setValue, getValues, resetField, trigger },
  { properties, definitions, isProducts, onScan, onSelect }
) => (
  <div className="flex flex-col">
    {/*label FIELDS FOR MULTIPRODUCTS*/}
    {renderMultiFields(
      { properties, definitions },
      { register, errors, setValue, getValues, resetField, trigger },
      ProductSelectActions,
      { onScan, onSelect }
    )}

    {/*/label COMMON FIELDS FOR ANY PRODUCT*/}
    <div
      className={cx("flex flex-col gap-4 border-base-100", {
        "border-t pt-6": isProducts,
      })}
    >
      {renderMonoFields(
        { properties, isProducts },
        {
          register,
          errors,
          setValue,
          getValues,
          resetField,
          trigger,
        },
        ProductSelectActions,
        { onScan, onSelect }
      )}
    </div>
  </div>
);

// label COMPONENT PRODUCTS_FORM
const ProductsForm = ({
  register,
  errors,
  setValue,
  getValues,
  resetField,
  trigger,
  watch,
  template,
  headerProps,
  setCurrentProduct,
  setStep,
  isLoadingSchema,
}) => {
  const navigate = useNavigate();

  const { preview_url, config = {} } = template;
  const { schema = {} } = config;
  const { definitions, properties } = schema;
  const isProducts = !!properties?.products;

  const watchPrice = watch(PRODUCT_FIELDS.OLD_PRICE);
  const watchDiscount = watch(PRODUCT_FIELDS.DISCOUNT_PERCENTAGE);
  const newPrice = getPrice(null, watchPrice, watchDiscount) ?? 0;

  const isShowPrice = hasProperties(properties, [
    PRODUCT_FIELDS.OLD_PRICE,
    PRODUCT_FIELDS.DISCOUNT_PERCENTAGE,
  ]);

  const handleSelectProduct =
    (view = PRODUCT_VIEWS.CATALOG) =>
    (e, key) => {
      setStep(STEPS.PRODUCTS_CATALOG);
      if (key) {
        setCurrentProduct(key);
      }

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("view", view);
      navigate(
        {
          pathname: window.location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    };

  if (isLoadingSchema) {
    return <ProductsForm.Skeleton />;
  }

  return (
    <CommonTemplate
      headerProps={{
        className: "bg-base-50",
        ...headerProps,
      }}
    >
      <div className="flex flex-col gap-4 pb-24">
        {/*label TEMPLATE VIEW*/}
        <div className="bg-base-50 rounded-es-lg rounded-ee-lg -mx-4 -mt-6 px-14 pb-12">
          <img
            src={preview_url}
            alt="template"
            className="w-full object-cover rounded-lg aspect-[9/16]"
          />
        </div>
        {/*label CONTENT*/}
        <div className="flex flex-col ">
          {renderFields(
            {
              register,
              errors,
              setValue,
              getValues,
              resetField,
              trigger,
            },
            {
              properties,
              definitions,
              isProducts,
              onScan: handleSelectProduct(PRODUCT_VIEWS.SCANNER),
              onSelect: handleSelectProduct(PRODUCT_VIEWS.CATALOG),
            }
          )}
          {isShowPrice && (
            <div className="flex justify-between py-6 border-t-[1px] border-base-100 -mx-4 p-4">
              <div className="flex flex-col gap-1">
                <span className="text-base-1000 text-sm font-semibold leading-none">
                  New Price
                </span>
                <span className="text-base-500 text-sm leading-none">
                  Price will adjust automatically
                </span>
              </div>
              <Title size="2xl" className="flex gap-1 text-nowrap">
                $ {newPrice}
              </Title>
            </div>
          )}
        </div>
        <div className="fixed bottom-12 left-0 right-0 flex gap-2 container px-4 mx-auto z-10">
          <Button variant="contained" fullWidth type="submit">
            Next
          </Button>
        </div>
      </div>
    </CommonTemplate>
  );
};

ProductsForm.Skeleton = () => (
  <CommonTemplate
    headerProps={{
      className: "bg-base-50",
    }}
  >
    <div className="flex flex-1 flex-col gap-4 pb-24">
      <div className="bg-base-50 rounded-es-lg rounded-ee-lg -mx-4 -mt-6 px-14 pb-12">
        <Skeleton.Round
          width="100%"
          sx={{ aspectRatio: "9 / 16", height: "auto" }}
        />
      </div>
      <div className="flex flex-col gap-4">
        {Array(5)
          .fill(null)
          .map((_, i) => (
            <Skeleton.Round key={i} height={64}></Skeleton.Round>
          ))}
      </div>
    </div>
  </CommonTemplate>
);

export default ProductsForm;
