// todo rename file to products.js
export const getPrice = (originalPrice, oldPrice, discountPercentage = 0) => {
  if (originalPrice) {
    return parseFloat(originalPrice.toFixed(2)) || undefined;
  }

  const discountDecimal = discountPercentage / 100;
  const discountAmount = oldPrice * discountDecimal;

  return parseFloat((oldPrice - discountAmount).toFixed(2)) || undefined;
};

const extractVolume = (variant) => {
  const lowerCaseVariant = variant.toLowerCase();
  const match = lowerCaseVariant.match(/[\d.]+(?:l|ml)/);

  return match ? match[0] : null;
};

const getProductFields = (
  product = {},
  { price, oldPrice, discountPercentage }
) => {
  const { displayName, name, bsImage, variant, brand } = product;

  return {
    brand: brand?.name,
    volume: extractVolume(variant),
    name: displayName ?? name,
    // todo remove TITLE field
    title: displayName ?? name,
    oldPrice,
    discountPercentage,
    price: getPrice(price, oldPrice, discountPercentage),
    img: bsImage.cdnUrl,
  };
};

export const templateDataAdapter = (obj) => {
  if (obj.product) {
    const { product, oldPrice, price, discountPercentage, ...rest } = obj;

    const productFields = getProductFields(product, {
      price,
      oldPrice,
      discountPercentage,
    });

    return {
      ...rest,
      ...productFields,
    };
  }

  if (obj.products) {
    const { products, oldPrice, price, discountPercentage, ...rest } = obj;
    return {
      ...rest,
      oldPrice,
      price: getPrice(price, oldPrice, discountPercentage),
      products: Object.fromEntries(
        Object.entries(products).map(([key, value]) => {
          const { product, price, oldPrice, discountPercentage, ...rest } =
            value;
          const productFields = getProductFields(product, {
            price,
            oldPrice,
            discountPercentage,
          });

          return [
            key,
            {
              ...rest,
              ...productFields,
            },
          ];
        })
      ),
    };
  }
  return obj;
};
