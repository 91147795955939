import * as palette from "./palette";

import colors from "themes/palette/colors.scss";

export const lightTheme = {
  palette: {
    primary: palette.getDefaultPrimary(),
    "primary-invert": palette.getDefaultPrimaryInvert(),
    secondary: palette.getDefaultSecondary(),
    success: palette.getDefaultSuccess(),
    error: palette.getDefaultError(),
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "Inter",
      "InterDisplay",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            padding: "16px 24px",
          },
          "&.MuiButton-text": {
            lineHeight: 1,
            padding: 0,
            minWidth: "auto",
            ":hover": {
              backgroundColor: "initial",
              textTransform: "initial",
              color: colors["brand-500"],
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          borderRadius: 12,
        },
        text: {
          padding: "0 !important",
        },
        sizeSmall: {
          fontSize: "14px",
          fontWeight: 400,
        },
        sizeMedium: {
          fontSize: "16px ",
        },
      },
      variants: [
        {
          props: { variant: "dashed" },
          style: {
            border: `1px dashed ${colors["base-100"]}`,
            borderRadius: "12px",
            padding: "8px 16px",
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
      variants: [
        {
          props: { variant: "contained" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: "12px",
            width: "48px",
            "&:hover": {
              backgroundColor: theme.palette.primary.main + "e6",
            },
          }),
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors["base-50"],
          borderRadius: 12,
          padding: "22px 24px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: "4px 0",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        primary: {
          fontSize: 16,
          lineHeight: 1,
          fontWeight: 500,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          fontSize: 20,
          lineHeight: 1,
          minWidth: 36,
          color: "inherit",
        },
      },
    },
    MuiFormControlLabel: {
      variants: [
        {
          props: { variant: "checkbox" },
          style: {
            margin: 0,
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "initial",
          },
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            display: "none",
            "&.Mui-checked + .MuiFormControlLabel-label": {
              backgroundColor: colors["brand-500"],
              color: colors["base-0"],
              boxShadow: "none",
            },
            "& +.MuiFormControlLabel-label": {
              padding: "12px 24px",
              borderRadius: 48,
              boxShadow: ` inset 0px 0px 0px 1px ${colors["base-100"]}`,
            },
          },
        },
      ],
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: 0,

          "&.Mui-error": {
            boxShadow: `0 0 0 1px ${colors["error"]}`,
          },
        },
        input: {
          padding: "30px 20px 18px",
          height: 16,
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
        inputMultiline: {
          padding: "30px 20px",
          minHeight: 160,
        },
        inputHiddenLabel: {
          paddingTop: "18px",
        },
        inputAdornedStart: {
          paddingLeft: "4px",
        },
        inputAdornedEnd: {
          paddingRight: "4px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          marginTop: 36,
          marginBottom: 0,
        },
        markLabel: {
          bottom: 40,
          top: "auto",
          fontSize: 16,
          color: colors["base-500"],
        },
        mark: {
          display: "none",
        },
        rail: {
          opacity: 1,
          backgroundColor: colors["base-100"],
        },
        thumb: {
          width: 24,
          height: 24,
          "&:after": {
            backgroundColor: colors["base-0"],
            width: 8,
            height: 8,
          },
          "&:hover, &.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input[type=number]": {
            "-moz-appearance": "textfield", // Firefox
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
            {
              "-webkit-appearance": "none", // Chrome, Safari, Opera
              margin: 0,
            },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors["base-500"],
          "&.Mui-focused": {
            color: colors["base-500"],
          },
        },
        filled: {
          transform: "translate(20px, 21px) !important",
          "&.Mui-focused": {
            transform: "translate(20px, 10px) scale(0.75) !important",
          },
          "&.MuiInputLabel-shrink": {
            transform: "translate(20px, 10px) scale(0.75) !important",
          },
        },
        sizeSmall: {
          transform: "none",
          fontSize: 14,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          width: "calc(100% - 32px)",
          height: "100%",
          borderRadius: 16,
          position: "relative",
          // backgroundColor: "transparent",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
            {
              marginTop: 0,
              marginLeft: 20,
              marginRight: 0,
            },
        },
        filled: {
          height: "auto",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors["brand-500"],
        },
        colorSecondary: {
          backgroundColor: colors["brand-100"],
        },
        label: {
          paddingLeft: 12,
          paddingRight: 12,
          fontSize: 14,
          fontWeight: 500,
        },
      },
      variants: [
        {
          props: { variant: "tag" },
          style: {
            borderRadius: 48,
            border: "1px solid",

            ".MuiChip-label": {
              padding: "0 24px",
            },
          },
        },
        {
          props: { variant: "tag-outlined" },
          style: {
            borderRadius: 48,
            background: "transparent",
            border: "1px solid",
            borderColor: colors["base-100"],
            ".MuiChip-label": {
              padding: "0 24px",
            },
          },
        },
        {
          props: { size: "big" },
          style: {
            height: 40,
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 28,
          padding: 0,
          borderRadius: 24,
          display: "flex",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 32,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(16px)",
            },
          },
        },
        switchBase: {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(24px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: colors["brand-500"],
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
          borderRadius: 24,
          transition: "all 200ms ease-in-out",
        },
        track: {
          borderRadius: 24,
          opacity: 1,
          backgroundColor: colors["base-50"],
          boxSizing: "border-box",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          rowGap: 6,
          "&.active": {
            color: colors["brand-600"],
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "none",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "none",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "none",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
};

export default lightTheme;
