import cx from "classnames";
import Button from "ui/Button";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ScanIcon } from "assets/icons/scan.svg";

const PlaylistCreateActions = ({
  className,
  disabled = false,
  onScan,
  onAdd,
}) => (
  <div className="grid grid-cols-2 gap-1">
    <Button
      variant="dashed"
      startIcon={<ScanIcon className="w-4" />}
      disabled={disabled}
      className={cx("h-12 border-base-500", className)}
      onClick={onScan}
    >
      Scan product
    </Button>
    <Button
      variant="dashed"
      startIcon={<PlusIcon className="w-4" />}
      disabled={disabled}
      className={cx("h-12 border-base-500", className)}
      onClick={onAdd}
    >
      Add manually
    </Button>
  </div>
);

export default PlaylistCreateActions;
