export const ALL = {
  id: "all",
  label: "ALL",
};

export const CATEGORIES = {
  recently: {
    id: "recently",
    label: "Recently Viewed",
  },
  bestSellers: {
    id: "bestSellers",
    label: "Clearance",
  },
  newArrivals: {
    id: "newArrivals",
    label: "New Arrivals",
  },
  onSalesPromotions: {
    id: "onSalesPromotions",
    label: "Flash Sales",
  },
  staffPicks: {
    id: "staffPicks",
    label: "Staff Picks",
  },
  branded: {
    id: "branded",
    label: "Limited Time Only",
  },
  holidays: {
    id: "holidays",
    label: "Holidays Specials",
  },
  wines: {
    id: "wines",
    label: "Wines",
  },
  spirits: { id: "spirits", label: "Spirits" },
  tasting: { id: "tasting", label: "Tasting" },
  seasonalSpecials: { id: "seasonalSpecials", label: "Seasonal Specials" },
  cocktailRecipes: { id: "cocktailRecipes", label: "Cocktail Recipes" },
};
