import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import convertToYup from "json-schema-yup-transformer";
import { useDispatch } from "react-redux";

import { showDrawer } from "store/ducks/ui/drawer";

import { useGetTemplateQuery } from "services/templates";
import { useCreateAdMutation, useApproveAdMutation } from "services/ads";

import { getComponent } from "utils/components";
import { templateDataAdapter } from "utils/templates";
import { formatDate } from "utils/date";

import { TEMPLATE_ID } from "constants/searchParams";
import { ADD_TO_PLAYLIST } from "constants/drawer";
import {
  INPUT_FORM_NAME,
  MONO_PRODUCT_FIELD,
  MULTI_PRODUCT_FIELD,
} from "constants/ads";

import ProductsForm from "pages/AdsCreate/pages/ProductsForm";
import SelectProduct from "pages/SelectProduct";
import FooterActions from "pages/AdsCreate/components/FooterActions";

import pages from "router/links";

import VideoPlayer from "../PlaylistCreate/pages/VideoPlayer";
import Loader from "components/Loader";

import { STEPS } from "./helpers";

const STEPS_MAP = {
  default: ProductsForm,
  [STEPS.PRODUCTS_FORM]: ProductsForm,
  [STEPS.PRODUCTS_CATALOG]: SelectProduct,
  [STEPS.VIDEO_PLAYER]: VideoPlayer,
  [STEPS.LOADER]: Loader,
};

const currentDate = formatDate();

const AdsCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(STEPS.PRODUCTS_FORM);
  const [isLoadingSchema, setIsLoadingSchema] = useState(true);
  const [schema, setSchema] = useState();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isProducts, setIsProducts] = useState(false);
  const [searchParams] = useSearchParams();

  const Component = getComponent(STEPS_MAP, step);
  const templateId = searchParams.get(TEMPLATE_ID);
  const { data: templateData = {}, isLoading } =
    useGetTemplateQuery(templateId);

  // todo
  // label CREATE AD
  const [
    createAd,
    {
      isLoading: isLoadingCreateAd,
      isSuccess: isSuccessCreateAd,
      isError: isErrorCreateAd,
      data: createData,
    },
  ] = useCreateAdMutation();

  // label APPROVE PLAYLIST
  const [
    approveAd,
    {
      data: approveData,
      isLoading: isLoadingApproveAd,
      isSuccess: isSuccessApproveAd,
    },
  ] = useApproveAdMutation();

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (isLoading && !schema) {
      setIsLoadingSchema(true);
    }

    if (!isLoading && !!schema) {
      setIsLoadingSchema(false);
    }
  }, [isLoading, schema]);

  useEffect(() => {
    if (templateData && !isLoading) {
      const { config: originalConfig = {} } = templateData;

      if (!originalConfig) {
        throw Error("Config incorrect or not found");
      }

      const { schema: originalSchema = {}, config = {} } = originalConfig;
      const { properties } = originalSchema;
      const isProducts = !!properties?.products;

      setIsProducts(isProducts);

      const schema = {
        ...originalSchema,
        properties: {
          ...originalSchema.properties,
          ...(isProducts
            ? {}
            : {
                [MONO_PRODUCT_FIELD]: {
                  type: "object",
                },
              }),
        },
        required: [
          INPUT_FORM_NAME,
          ...(isProducts
            ? Object.keys(properties.products?.properties || {})
            : [MONO_PRODUCT_FIELD]),
          ...(originalSchema?.required || []),
        ],
      };
      const yupSchema = convertToYup(schema, config);

      setSchema(yupSchema);
    }
  }, [templateData, isLoading]);

  // label CREATE AD
  useEffect(() => {
    if (isLoadingCreateAd) {
      setStep(STEPS.LOADER);
    }
  }, [isLoadingCreateAd]);

  useEffect(() => {
    if (isSuccessCreateAd) {
      setStep(STEPS.VIDEO_PLAYER);
    }
  }, [isSuccessCreateAd]);

  useEffect(() => {
    if (isErrorCreateAd) {
      setStep(STEPS.PRODUCTS_FORM);
    }
  }, [isErrorCreateAd]);

  // label APPROVE AD
  useEffect(() => {
    if (isSuccessApproveAd) {
      navigate(pages.playlists.path);
    }
  }, [isSuccessApproveAd, navigate]);

  const VideoPlayerActions = (props) => (
    <div className="sticky bottom-6 flex gap-2">
      <FooterActions {...props} />
    </div>
  );

  // label COMPONENT PROPS
  const getComponentProps = useCallback(
    (step) => {
      let props = {};
      switch (step) {
        default:
        case STEPS.PRODUCTS_FORM:
          props = {
            headerProps: {
              title: `${templateData?.name} ${currentDate}`,
              onBack: () => {
                navigate(pages.templates.path);
              },
            },
            isLoading: isLoadingCreateAd,
            isLoadingSchema,
          };
          break;
        case STEPS.PRODUCTS_CATALOG:
          props = {
            headerProps: {
              title: "Select a Product",
              onBack: () => setStep(STEPS.PRODUCTS_FORM),
              deleteParams: ["search", "view"],
            },
            deleteParams: ["search", "view"],
          };
          break;
        case STEPS.VIDEO_PLAYER:
          props = {
            headerProps: {
              title: "New Ad",
              onBack: () => setStep(STEPS.PRODUCTS_FORM),
            },
            videoUrl: createData?.video_url,
            poster: createData?.min_preview_url,
            actionsComponent: VideoPlayerActions,
            actionsComponentProps: {
              onAddToPlaylist: () => {
                dispatch(
                  showDrawer({
                    content: ADD_TO_PLAYLIST,
                    headerProps: {
                      title: "Move to Playlist",
                    },
                    data: {
                      selectedAds: [createData.id],
                    },
                  })
                );
              },
              onSave: () => {
                approveAd(createData.id);
              },
            },
          };
          break;
      }

      return props;
    },
    [
      approveAd,
      createData?.id,
      createData?.video_url,
      dispatch,
      isLoadingCreateAd,
      isLoadingSchema,
      templateData?.name,
    ]
  );

  const handleSelectProduct = (e, product) => {
    if (isProducts) {
      setValue(
        `${MULTI_PRODUCT_FIELD}.${currentProduct}.${MONO_PRODUCT_FIELD}`,
        product
      );
    } else {
      setValue(MONO_PRODUCT_FIELD, product);
    }
    setStep(STEPS.PRODUCTS_FORM);
  };

  const onSubmit = (data) => {
    createAd({
      templateId,
      data: templateDataAdapter(data),
    });
  };

  return (
    <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))}>
      {/*todo*/}
      <Component
        isLoading={isLoading || !templateData}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        resetField={resetField}
        trigger={trigger}
        watch={watch}
        template={templateData}
        setCurrentProduct={setCurrentProduct}
        setStep={setStep}
        onChangeStep={setStep}
        onSelect={handleSelectProduct}
        {...getComponentProps(step)}
      />
    </form>
  );
};

export default AdsCreate;
