import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

import CommonTemplate from "templates/CommonTemplate";

import { EMAIL } from "constants/APIs";

import Avatar from "ui/Avatar";
import Link from "ui/Link";
import Button from "ui/Button";

const ProfileAvatar = ({ name }) => {
  const shortName = name?.slice(0, 1).toUpperCase();

  return (
    <div className="rounded-full border-[3px] border-base-100">
      <Avatar
        alt={name}
        sx={{
          width: 120,
          height: 120,
          margin: "5px",
        }}
      >
        {shortName && <span className="text-5xl">{shortName}</span>}
      </Avatar>
    </div>
  );
};

const Profile = () => {
  const navigate = useNavigate();
  const { logout, getUser } = useAuth();
  const { name, email, phone } = getUser();

  const handleLogout = () => {
    logout().then(({ success, redirectTo }) => {
      if (success) {
        navigate(redirectTo);
      }
    });
  };

  return (
    <CommonTemplate
      headerProps={{
        title: "Profile",
      }}
    >
      <div className="flex flex-1 flex-col justify-between">
        {/*label INFO BLOCK*/}
        <div className="flex flex-col items-center gap-8">
          <div className="">
            <ProfileAvatar name={name} />
          </div>
          <div className="flex flex-col items-center gap-2">
            <span className="text-base-1000 text-2xl font-medium">{name}</span>
            <div className="flex flex-col items-center gap-1 text-sm">
              <span className="text-base-500">{email}</span>
              {phone && <span className="text-base-500">{phone}</span>}
            </div>
          </div>
        </div>

        {/*label CONTACT BLOCK*/}
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-4 items-center px-14">
            <span className="flex text-center text-base-500">
              To change your contact information, please contact Daniel at this
              e-mail:
            </span>
            <Link
              href={`mailto:${EMAIL}`}
              className="text-[#131313] no-underline"
            >
              {EMAIL}
            </Link>
          </div>

          <Button variant="contained" onClick={handleLogout} color="error">
            Log out
          </Button>
        </div>
      </div>
    </CommonTemplate>
  );
};

export default Profile;
