import { useState } from "react";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";
import useDeleteParams from "hooks/useDeleteParams";

import { useGetProductsListQuery } from "services/catalog";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";

import Title from "components/Title";
import SearchInput from "components/SearchInput";
import MediaCard from "components/MediaCard";
import SkeletonWrapper from "components/SkeletonWrapper";

import styles from "./styles.module.scss";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const Footer = ({ name, title, value }) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <span className="text-base-500 text-sm">{name}</span>
      <Title className="text-base-1000 line-clamp-2 overflow-hidden text-ellipsis">
        {title}
      </Title>
      <span className="text-base-1000 text-sm mt-1">{value}</span>
    </div>
  );
};

const Catalog = ({
  headerProps = {
    title: "Select a Product",
  },
  deleteParams,
  onSelect = () => {},
}) => {
  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch("");
  const debouncedSearch = useDebounce(700);
  const deleteParamsFromUrl = useDeleteParams();

  const { data: { data: productsData = [], total } = {}, isLoading } =
    useGetProductsListQuery({
      page,
      limit: PAGINATION.limit,
      search,
    });

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  return (
    <CommonTemplate
      headerProps={headerProps}
      contentProps={{ className: "gap-8" }}
    >
      <div className="flex flex-col gap-4 ">
        {/*label SEARCH*/}
        <SearchInput
          placeholder="Search Products by Name"
          defaultValue={search}
          onChange={({ target }) => {
            debouncedSearch(() => {
              setPage(1);
              setSearch(target.value);
            });
          }}
        />
        {/*<div className="flex gap-1 py-4 -mx-4 pl-4 overflow-x-auto">*/}
        {/*  {Object.values(categories).map(({ id, label }) => (*/}
        {/*    <Chip*/}
        {/*      key={id}*/}
        {/*      label={label}*/}
        {/*      size="big"*/}
        {/*      variant={`tag${id !== selectedCategory ? "-outlined" : ""}`}*/}
        {/*      color={id !== selectedCategory ? "default" : "primary"}*/}
        {/*      clickable*/}
        {/*      onClick={() => setSelectedCategory(id)}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
      <div className="flex flex-col">
        {/*<div className="py-4">*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    startIcon={<FilterIcon className="w-4 h-4" />}*/}
        {/*    size="small"*/}
        {/*    className="px-4 py-2"*/}
        {/*  >*/}
        {/*    Filter <span className="text-base-0/[.4] ml-1">(3)</span>*/}
        {/*  </Button>*/}
        {/*</div>*/}
        <InfiniteScroll
          next={handleMoreData}
          dataLength={productsData.length}
          hasMore={page * PAGINATION.limit < total}
          scrollThreshold={0.55}
          loader={<span>Loading...</span>}
        >
          <div className="grid grid-cols-2 gap-x-2 gap-y-4 ">
            {(isLoading
              ? Array.from({ length: 6 }, () => ({ uuid: uuidv4() }))
              : productsData
            ).map(
              ({
                uuid,
                productId,
                bsImage,
                brand,
                name,
                displayName,
                variant,
              } = {}) => (
                <SkeletonWrapper
                  key={uuid || productId}
                  condition={isLoading}
                  Component={MediaCard.Skeleton}
                  skeletonProps={{ aspectRatio: "13 / 17" }}
                >
                  <MediaCard
                    key={productId}
                    img={bsImage?.cdnUrl}
                    className={cx(styles.aspect, "items-start")}
                    imageWrapperClassName="bg-base-50 rounded-lg w-full h-full py-5 justify-center items-center"
                    objectContain
                    component={Button}
                    componentProps={{
                      onClick: (e) => {
                        // todo
                        deleteParamsFromUrl(deleteParams);
                        onSelect(e, {
                          productId,
                          bsImage,
                          brand,
                          name,
                          displayName,
                          variant,
                        });
                      },
                    }}
                    Description={
                      <Footer
                        name={brand?.name}
                        title={displayName ?? name}
                        value={variant}
                      />
                    }
                  />
                </SkeletonWrapper>
              )
            )}
          </div>
        </InfiniteScroll>
      </div>
    </CommonTemplate>
  );
};

export default Catalog;
