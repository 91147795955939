import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import CatalogPage from "pages/Catalog";
import ScannerPage from "pages/Scanner";

import SwitchView from "./components/SwitchView";
import { PRODUCT_VIEWS } from "constants/index";
import { VIEW_MODE } from "constants/searchParams";

const SelectProduct = (componentProps, headerProps, onSelect) => {
  const [searchParams] = useSearchParams();
  const viewMode =
    searchParams.get(VIEW_MODE)?.toLocaleUpperCase() === PRODUCT_VIEWS.SCANNER
      ? PRODUCT_VIEWS.SCANNER
      : PRODUCT_VIEWS.CATALOG;

  const [view, setView] = useState(viewMode ?? PRODUCT_VIEWS.CATALOG);

  const Component = view === PRODUCT_VIEWS.SCANNER ? ScannerPage : CatalogPage;

  return (
    <div className="relative">
      <Component
        headerProps={headerProps}
        onSelect={onSelect}
        {...componentProps}
      />
      <div className="fixed bottom-4 left-0 right-0 container px-4 mx-auto z-10 flex justify-center">
        <SwitchView value={view} onClick={setView} />
      </div>
    </div>
  );
};

export default SelectProduct;
